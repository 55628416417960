import { combineReducers } from 'redux';
import { appReducer } from './app';
import { loginReducer } from './login';
import { meReducer } from './meReducer';
import { socialPostReducer } from './socialPostReducer';
import { settingReducer } from './settingReducer';
import { calendarCampaignsReducer } from './calendar_campaigns';
const rootReducer = combineReducers({
  loginReducer,
  appReducer,
  meReducer,
  socialPostReducer,
  settingReducer,
  calendarCampaignsReducer
});

export default rootReducer;
