import axios, { AxiosRequestConfig } from 'axios';
import { BASE_ROOT, USER_TOKEN } from 'src/util/config';

const fetchApiAdmin = async (endPoint, method, params, _data) => {
  let configs: AxiosRequestConfig = {
    url: `${BASE_ROOT}/api/admin/${endPoint}`,
    method: method,
    headers: {
      Authorization: `Bearer ${USER_TOKEN}`
    },
    params,
    data: _data
  };

  let response = await axios(configs);
  return response;
};

export const getAllHrApi = async params => {
  return fetchApiAdmin('human_resources', 'GET', params, null);
};

export const addNewHrApi = async data => {
  return fetchApiAdmin('human_resources', 'POST', null, data);
};

export const updateInformationHrApi = async (id, data) => {
  return fetchApiAdmin(`human_resources/${id}`, 'PUT', null, data);
};

export const destroyHrApi = async id => {
  return fetchApiAdmin(`human_resources/${id}`, 'DELETE', null, null);
};

export const getSystemMenuApi = async () => {
  return fetchApiAdmin('admin_menu_all', 'GET', null, null);
};

export const getAdminMenuApi = async () => {
  return fetchApiAdmin('admin_menu', 'GET', null, null);
};
