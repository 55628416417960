import moment from 'moment';
import * as types from 'src/constants/store/calendar_campaigns';

const current_date = new Date();

const initialState = {
  filter: {
    typeWatch: 'dayGridMonth',
    time: moment(current_date).format('YYYY-MM-DD'),
    approvalStatus: 'all',
    typeEvent: 'all'
  }
};

export const calendarCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILTER: {
      let { typeWatch, time, approvalStatus, typeEvent } = action.payload;
      return {
        filter: {
          typeWatch: typeWatch || state.filter.typeWatch,
          time: time || state.filter.time,
          approvalStatus: approvalStatus || state.filter.approvalStatus,
          typeEvent: typeEvent || state.filter.typeEvent
        }
      };
    }
    case types.RESET_FILTER: {
      return initialState;
    }
    default:
      return state;
  }
};
